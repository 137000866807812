body {
    margin:           0;
    padding:          0;
    font-family:      sans-serif;
    font-size:        17px;
    line-height:      1.4;
    background:       rgb(17, 17, 17);
    /*scroll-snap-type: y proximity;*/
}

.scroll-snap {
    /*scroll-snap-align: start;*/
}

.smooth {
    -webkit-font-smoothing: antialiased;
}

.ReactModal__Content iframe {
    display: block;
}

.ReactModal__Overlay {
    background: rgba(0, 0, 0, 0.85) !important;
    z-index:    10000;
}

.ReactModal__Content {
    left:          50% !important;
    top:           50% !important;
    -webkit-transform:     translate(-50%, -50%) !important;
            transform:     translate(-50%, -50%) !important;
    background:    black !important;
    border-radius: 0 !important;
}

.animated-arrow {
    -webkit-animation: arrow 1s both infinite;
    animation:         arrow 1s both infinite;
}

@-webkit-keyframes arrow {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
    }
}

@keyframes arrow {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
                transform: translateY(-15px);
    }
}

@media (max-width: 400px) {
    .top-scroller {
        -webkit-transform: translate(0, -10vh);
                transform: translate(0, -10vh);
    }
}
.scroll-downs {
    /*width:  37px;*/
}

.mousey {
    width:         3px;
    padding:       10px 15px;
    height:        35px;
    border:        2px solid #fff;
    border-radius: 25px;
    box-sizing:    content-box;
}

.scroller {
    width:                     3px;
    height:                    10px;
    border-radius:             25%;
    background-color:          #fff;
    -webkit-animation-name:            scroll;
            animation-name:            scroll;
    -webkit-animation-duration:        2.2s;
            animation-duration:        2.2s;
    -webkit-animation-timing-function: cubic-bezier(.15, .41, .69, .94);
            animation-timing-function: cubic-bezier(.15, .41, .69, .94);
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
}

@-webkit-keyframes scroll {
    0% {
        opacity: 0;
    }
    10% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity:   1;
    }
    100% {
        -webkit-transform: translateY(15px);
                transform: translateY(15px);
        opacity:   0;
    }
}

@keyframes scroll {
    0% {
        opacity: 0;
    }
    10% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity:   1;
    }
    100% {
        -webkit-transform: translateY(15px);
                transform: translateY(15px);
        opacity:   0;
    }
}
.portfolio-item:hover div {
    opacity: 1 !important;
}

@media (max-width: 480px) {
    .portfolio-item div {
        opacity: 1 !important;
    }
}

.modal-close-button {
    top:   13px;
    right: 11px;
}

@media (min-width: 481px) {
    .modal-close-button {
        top:   28px;
        right: 26px;
    }
}

.close {
    position: relative;
    display:  inline-block;
    width:    50px;
    height:   50px;
    overflow: hidden;
}

.close:hover::before, .close:hover::after {
    /*background: #1ebcc5;*/
    opacity: .6;
}

.close::before, .close::after {
    content:    '';
    position:   absolute;
    height:     2px;
    width:      100%;
    top:        50%;
    left:       0;
    margin-top: -1px;
    /*background: #000;*/
    background: white;
    opacity:    1;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
}

.close::before {
    -webkit-transform: rotate(45deg);
    transform:         rotate(45deg);
}

.close::after {
    -webkit-transform: rotate(-45deg);
    transform:         rotate(-45deg);
}

.close.big {
    -webkit-transform: scale(3);
    transform:         scale(3);
}

.close.hairline::before, .close.hairline::after {
    height: 1px;
}

.close.thick::before, .close.thick::after {
    border-radius: 3px;
    height:        4px;
    margin-top:    -2px;
}

.close.black::before, .close.black::after {
    height:     8px;
    margin-top: -4px;
}

.close.heavy::before, .close.heavy::after {
    height:     12px;
    margin-top: -6px;
}

.close.pointy::Before, .close.pointy::after {
    width: 200%;
    left:  -50%;
}

.close.rounded::before, .close.rounded::after {
    border-radius: 5px;
}

.close.blades::before, .close.blades::after {
    border-radius: 5px 0;
}

.close.warp::before, .close.warp::after {
    border-radius: 120% 0;
}

.close.fat::before, .close.fat::after {
    border-radius: 100%;
}

/*small {*/
/*display: block;*/
/*padding: 5rem;*/
/*font: .8rem/1 sans-serif;*/
/*color: #777;*/
/*text-align: center;*/
/*}*/

/*small a {*/
/*color: #1ebcc5;*/
/*text-decoration: none;*/
/*border-bottom: 1px solid #ccc;*/
/*}*/

/*small a:hover {*/
/*border-bottom-color: #222;*/
/*}*/


.hovbtn {
    display:         inline-block;
    color:           #fff;
    cursor:          pointer;
    line-height:     45px;
    position:        relative;
    text-decoration: none;
    text-transform:  uppercase;
}

.hovbtn-2 {
    letter-spacing: 0;
    -webkit-transition:     all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    transition:     all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
}

.hovbtn-2::after {
    position:         absolute;
    left:             0;
    content:          '';
    width:            100%;
    height:           2px;
    background:       white;
    bottom:           0px;
    -webkit-transform:        scale(0, 1);
            transform:        scale(0, 1);
    -webkit-transition:       -webkit-transform .3s;
    transition:       -webkit-transform .3s;
    transition:       transform .3s;
    transition:       transform .3s, -webkit-transform .3s;
    -webkit-transform-origin: left top;
            transform-origin: left top;
}

.hovbtn-2:hover::after {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
}
@media (max-height: 480px) {
    .ruben-portret {
        width:  140px !important;
        height: 140px !important;
    }

    .contact-inner-block {
        padding: 0 1rem 1rem !important;
    }
}
.hamburger-topright {
    right: 30px;
    top:   30px;
}

@media (max-width: 480px) {
    .hamburger-topright {
        right: 15px;
        top:   15px;
    }
}

#hamburger-icon {
    width:    48px;
    height:   48px;
    position: relative;
    display:  block;
    margin:   0;
    cursor:   pointer;
}

#hamburger-icon .line {
    display:            block;
    background:         #ecf0f1;
    width:              48px;
    height:             4px;
    position:           absolute;
    left:               0;
    border-radius:      4px;
    transition:         all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition:    all 0.4s;
}

#hamburger-icon .line.line-1 {
    top: 25%;
}

#hamburger-icon .line.line-2 {
    top: 50%;
}

#hamburger-icon .line.line-3 {
    top: 75%;
}

#hamburger-icon:hover .line-1, #hamburger-icon:focus .line-1 {
    transform:         translateY(-4px);
    -webkit-transform: translateY(-4px);
    -moz-transform:    translateY(-4px);
}

#hamburger-icon:hover .line-3, #hamburger-icon:focus .line-3 {
    transform:         translateY(4px);
    -webkit-transform: translateY(4px);
    -moz-transform:    translateY(4px);
}

#hamburger-icon.active .line-1 {
    transform:         translateY(12px) translateX(0) rotate(45deg);
    -webkit-transform: translateY(12px) translateX(0) rotate(45deg);
    -moz-transform:    translateY(12px) translateX(0) rotate(45deg);
}

#hamburger-icon.active .line-2 {
    opacity: 0;
}

#hamburger-icon.active .line-3 {
    transform:         translateY(-12px) translateX(0) rotate(-45deg);
    -webkit-transform: translateY(-12px) translateX(0) rotate(-45deg);
    -moz-transform:    translateY(-12px) translateX(0) rotate(-45deg);
}

.menu-item span {
    border-bottom: 2px solid transparent;
    -webkit-transition:    border .2s;
    transition:    border .2s;
}

.menu-item:hover span {
    border-bottom: 2px solid white;
}
