body {
    margin:           0;
    padding:          0;
    font-family:      sans-serif;
    font-size:        17px;
    line-height:      1.4;
    background:       rgb(17, 17, 17);
    /*scroll-snap-type: y proximity;*/
}

.scroll-snap {
    /*scroll-snap-align: start;*/
}

.smooth {
    -webkit-font-smoothing: antialiased;
}

.ReactModal__Content iframe {
    display: block;
}

.ReactModal__Overlay {
    background: rgba(0, 0, 0, 0.85) !important;
    z-index:    10000;
}

.ReactModal__Content {
    left:          50% !important;
    top:           50% !important;
    transform:     translate(-50%, -50%) !important;
    background:    black !important;
    border-radius: 0 !important;
}

.animated-arrow {
    -webkit-animation: arrow 1s both infinite;
    animation:         arrow 1s both infinite;
}

@-webkit-keyframes arrow {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
    }
}

@keyframes arrow {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-30px);
    }
    60% {
        transform: translateY(-15px);
    }
}

@media (max-width: 400px) {
    .top-scroller {
        transform: translate(0, -10vh);
    }
}