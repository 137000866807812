.hamburger-topright {
    right: 30px;
    top:   30px;
}

@media (max-width: 480px) {
    .hamburger-topright {
        right: 15px;
        top:   15px;
    }
}

#hamburger-icon {
    width:    48px;
    height:   48px;
    position: relative;
    display:  block;
    margin:   0;
    cursor:   pointer;
}

#hamburger-icon .line {
    display:            block;
    background:         #ecf0f1;
    width:              48px;
    height:             4px;
    position:           absolute;
    left:               0;
    border-radius:      4px;
    transition:         all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition:    all 0.4s;
}

#hamburger-icon .line.line-1 {
    top: 25%;
}

#hamburger-icon .line.line-2 {
    top: 50%;
}

#hamburger-icon .line.line-3 {
    top: 75%;
}

#hamburger-icon:hover .line-1, #hamburger-icon:focus .line-1 {
    transform:         translateY(-4px);
    -webkit-transform: translateY(-4px);
    -moz-transform:    translateY(-4px);
}

#hamburger-icon:hover .line-3, #hamburger-icon:focus .line-3 {
    transform:         translateY(4px);
    -webkit-transform: translateY(4px);
    -moz-transform:    translateY(4px);
}

#hamburger-icon.active .line-1 {
    transform:         translateY(12px) translateX(0) rotate(45deg);
    -webkit-transform: translateY(12px) translateX(0) rotate(45deg);
    -moz-transform:    translateY(12px) translateX(0) rotate(45deg);
}

#hamburger-icon.active .line-2 {
    opacity: 0;
}

#hamburger-icon.active .line-3 {
    transform:         translateY(-12px) translateX(0) rotate(-45deg);
    -webkit-transform: translateY(-12px) translateX(0) rotate(-45deg);
    -moz-transform:    translateY(-12px) translateX(0) rotate(-45deg);
}
