.portfolio-item:hover div {
    opacity: 1 !important;
}

@media (max-width: 480px) {
    .portfolio-item div {
        opacity: 1 !important;
    }
}

.modal-close-button {
    top:   13px;
    right: 11px;
}

@media (min-width: 481px) {
    .modal-close-button {
        top:   28px;
        right: 26px;
    }
}

.close {
    position: relative;
    display:  inline-block;
    width:    50px;
    height:   50px;
    overflow: hidden;
}

.close:hover::before, .close:hover::after {
    /*background: #1ebcc5;*/
    opacity: .6;
}

.close::before, .close::after {
    content:    '';
    position:   absolute;
    height:     2px;
    width:      100%;
    top:        50%;
    left:       0;
    margin-top: -1px;
    /*background: #000;*/
    background: white;
    opacity:    1;
    transition: opacity .2s;
}

.close::before {
    -webkit-transform: rotate(45deg);
    -moz-transform:    rotate(45deg);
    -ms-transform:     rotate(45deg);
    -o-transform:      rotate(45deg);
    transform:         rotate(45deg);
}

.close::after {
    -webkit-transform: rotate(-45deg);
    -moz-transform:    rotate(-45deg);
    -ms-transform:     rotate(-45deg);
    -o-transform:      rotate(-45deg);
    transform:         rotate(-45deg);
}

.close.big {
    -webkit-transform: scale(3);
    -moz-transform:    scale(3);
    -ms-transform:     scale(3);
    -o-transform:      scale(3);
    transform:         scale(3);
}

.close.hairline::before, .close.hairline::after {
    height: 1px;
}

.close.thick::before, .close.thick::after {
    border-radius: 3px;
    height:        4px;
    margin-top:    -2px;
}

.close.black::before, .close.black::after {
    height:     8px;
    margin-top: -4px;
}

.close.heavy::before, .close.heavy::after {
    height:     12px;
    margin-top: -6px;
}

.close.pointy::Before, .close.pointy::after {
    width: 200%;
    left:  -50%;
}

.close.rounded::before, .close.rounded::after {
    border-radius: 5px;
}

.close.blades::before, .close.blades::after {
    border-radius: 5px 0;
}

.close.warp::before, .close.warp::after {
    border-radius: 120% 0;
}

.close.fat::before, .close.fat::after {
    border-radius: 100%;
}

/*small {*/
/*display: block;*/
/*padding: 5rem;*/
/*font: .8rem/1 sans-serif;*/
/*color: #777;*/
/*text-align: center;*/
/*}*/

/*small a {*/
/*color: #1ebcc5;*/
/*text-decoration: none;*/
/*border-bottom: 1px solid #ccc;*/
/*}*/

/*small a:hover {*/
/*border-bottom-color: #222;*/
/*}*/
